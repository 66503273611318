<template>
  <div class="slider-demo-block" ref="slider">
    <!-- <span class="demonstration">Customized initial value</span> -->
    <el-slider vertical v-model="value" height="200px" :style="{}" />
  </div>
</template>

<script lang="js">
import {  defineComponent, ref , onMounted  } from "vue";
import { ElSlider } from "element-plus";

export default defineComponent({
  name: "Slider",
  components:{ ElSlider },
  setup() {
     const value = ref(0);
     const slider = ref(null);

     onMounted(()=>{
        console.log(slider.value.offsetTop)
     })
    //  const style={ }
      return { value , slider };
    },
});
</script>

<style scope>
.slider-demo-block {
  position: fixed;
  left: 500px;
  top: 300px;
}
</style>
